import React, { ReactNode, useContext } from "react";

interface SetupContextType {
  open_setup: boolean;
  setOpenSetup: (setup: boolean) => void;
}

const SetupContext = React.createContext<SetupContextType | undefined>(
  undefined
);

function SetupProvider({ children }: { children: ReactNode }) {
  const initialState = () => {
    const stored_setup = localStorage.getItem("setup");
    return stored_setup ? JSON.parse(stored_setup) : true;
  };

  const [open_setup, _setOpenSetup] = React.useState<boolean>(initialState());

  const setOpenSetup = (setup: boolean) => {
    localStorage.setItem("setup", JSON.stringify(setup));
    _setOpenSetup(setup);
  };

  return (
    <SetupContext.Provider value={{ open_setup, setOpenSetup }}>
      {children}
    </SetupContext.Provider>
  );
}

const useSetup = () => {
  const context = useContext(SetupContext);
  if (!context) {
    throw new Error("useSetup must be used within a SetupProvider");
  }
  return context;
};

export { SetupProvider, useSetup };
